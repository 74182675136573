.testimonials-carousel {
  position: unset !important;
  .react-multiple-carousel__arrow {
    position: absolute;
  }

  .react-multiple-carousel__arrow--left {
    // left: calc(-3% - 5px);
    left: -50px;
  }

  .react-multiple-carousel__arrow--right {
    // right: calc(-3% - 5px);
    right: -50px;
  }

  .react-multi-carousel-dot-list {
    bottom: -50px !important;
  }
}
