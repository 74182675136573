.scroll-tabs {
  height: 2500px;
}

/* Make the row sticky so when you scroll down to it, it will stick to the top until it gets to the next section. 
Set it to be 100px from the top */
.scroll-tabs > .tab {
  position: sticky !important;
  top: 0px;
}

/* Make the row sticky so when you scroll down to it, it will stick to the top until it gets to the next section. 
Set it to be 100px from the top */
.scroll-tabs > .et_pb_row {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
}

.flex-end {
  align-items: end;
}
