.video-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  bottom: 0;
  will-change: width, height;

  .ipadDesktop {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    will-change: width, height;
    transition: all 1s;

    z-index: 2;
  }

  .ipadMob {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
  }

  .video-content {
    position: absolute;
    // top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    object-fit: fill;
    z-index: 1;
    padding-top: 1.5vw;
    will-change: width, height;
    transition: all 1s;

    #video-vimeo {
      width: 100%;

      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    // border-radius: 5%;
  }

  hr {
    transition: all 1s;
  }

  .bottom-line {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.video-container-h-1 {
  height: 53.64vw;

  .ipadDesktop {
    width: 75vw;
    height: 53.64vw;
  }

  .video-content {
    width: 72.18vw;
    max-width: 72.18vw;
    height: 53.64vw;
  }

  .bottom-line {
    left: 50%;
    transform: translate(-50%, 0);
  }

  hr {
    margin-top: 53.64vw;
  }
}

.video-container-h-2 {
  height: 50.05vw;

  .ipadDesktop {
    width: 70vw;
    height: 50.05vw;
  }

  .video-content {
    width: 67.34vw;
    max-width: 67.34vw;
    height: 50.05vw;
  }

  .bottom-line {
    left: 50%;
    transform: translate(-50%, 0);
  }

  hr {
    margin-top: 48.5vw;
  }
}

.video-container-h-3 {
  height: 46.48vw;

  .ipadDesktop {
    width: 65vw;
    height: 46.48vw;
  }

  .video-content {
    width: 62.54vw;
    max-width: 62.54vw;
    height: 46.48vw;
  }

  .bottom-line {
    left: 50%;
    transform: translate(-50%, 0);
  }

  hr {
    margin-top: 46.4vw;
  }
}

.video-container-h-4 {
  height: 42.9vw;

  .ipadDesktop {
    width: 60vw;
    height: 42.9vw;
  }

  .video-content {
    width: 57.72vw;
    max-width: 57.72vw;
    height: 42.9vw;
  }

  .bottom-line {
    left: 50%;
    transform: translate(-50%, 0);
  }

  hr {
    margin-top: 42.85vw;
  }
}

.videoBg {
  transition: all 1s;
}

.videoBg-1 {
  width: 80vw;
  height: 66.51vw;
}

.videoBg-2 {
  width: 75vw;
  height: 62.35vw;
}

.videoBg-3 {
  width: 70vw;
  height: 58.2;
}

.videoBg-4 {
  width: 65vw;
  height: 54.04vw;
}

.videoBg-mob {
  width: 80vw;
  height: 71.5436;
}
