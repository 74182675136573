* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile {
  max-width: 100vw;
  overflow-x: hidden !important;
  background: linear-gradient(90deg, #efefef 0%, #fff 100%);
}

.bgGrd {
  background: linear-gradient(90deg, #efefef 0%, #fff 100%);
}

.Phone {
  border-top-left-radius: 46px;
  border-top-right-radius: 46px;
  border-top: 1.5px solid transparent;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  backdrop-filter: blur(55.367469787597656px);
  background: linear-gradient(to right, #202020, #202020),
    linear-gradient(to top, #202020 0%, #e3d6c7 100.52%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.Phone_m {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1.5px solid transparent;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
  backdrop-filter: blur(55.367469787597656px);
  background: linear-gradient(to right, #202020, #202020),
    linear-gradient(to top, #202020 0%, #e3d6c7 100.52%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.Phone-radius {
}

.Phone1 {
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  backdrop-filter: blur(55.367469787597656px);
  background: linear-gradient(to right, #202020, #202020),
    linear-gradient(to top, #202020 0%, #e3d6c7 100.52%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.Phone1_m {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  backdrop-filter: blur(55.367469787597656px);
  background: linear-gradient(to right, #202020, #202020),
    linear-gradient(to top, #202020 0%, #e3d6c7 100.52%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

@media (min-width: 768px) {
  .tower {
    background-image: url("../public/tower.svg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
  .Phone {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.color-animation {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    transparent 24%,
    transparent 25%,
    transparent 100%
  );
  /* background: #efefef;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
  background-position: center; */

  animation: spread 1s ease-in-out forwards;
  opacity: 0;
  border-radius: 50%;
  z-index: 0;
  /* z-index: 1; */
}

.dark {
  background-color: #000;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
  background-position: center;
}

@keyframes spread {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 1;
  }
}

.glowing-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  height: 37%;
  aspect-ratio: 1 / 1;
  /* height: 15%; */
  border-radius: 50%;
  background-color: transparent;
  /* box-shadow: 0 0 10px 10px #b400ff, 0 0 20px 20px #ff00d4; */
  /* z-index: 80; */
  -webkit-animation: glowing 2s ease-in-out infinite alternate;
  -moz-animation: glowing 2s ease-in-out infinite alternate;
  animation: glowing 2s ease-in-out infinite alternate;
}

.glowing-circle-mobile {
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50.5%, -50%);
  width: 44.15%;
  height: 19.145%;
  /* aspect-ratio: 1 / 1; */

  /* height: 15%; */
  border-radius: 50%;
  border: 2.5vw solid #fff;
  background-color: transparent;
  /* box-shadow: 0 0 10px 10px gray, 0 0 20px 20px #fff; */
  -webkit-animation: glowing-mobile 2s ease-in-out infinite alternate;
  -moz-animation: glowing-mobile 2s ease-in-out infinite alternate;
  animation: glowing-mobile 2s ease-in-out infinite alternate;
  /* z-index: 80; */
}

@keyframes glowing {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px lightgray, 0 0 30px gray;
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px lightgray, 0 0 40px gray;
  }
}

@-webkit-keyframes glowing {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px lightgray, 0 0 30px gray;
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px lightgray, 0 0 40px gray;
  }
}

@keyframes glowing-mobile {
  from {
    box-shadow: 0 0 0px #fff, 0 0 10px lightgray, 0 0 20px gray;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 20px lightgray, 0 0 30px gray;
  }
}

@-webkit-keyframes glowing-mobile {
  from {
    box-shadow: 0 0 5px #fff, 0 0 15px lightgray, 0 0 25px gray;
  }
  to {
    box-shadow: 0 0 15px #fff, 0 0 25px lightgray, 0 0 35px gray;
  }
}

.react-multi-carousel-list {
  /* position: static !important; */
}

.react-multi-carousel-dot-list {
  bottom: -10px !important;
  /*
   z-index: 100; */
}

.react-multi-carousel-track {
  justify-content: space-between;
}

.react-multi-carousel-item {
  display: flex;
  justify-items: center;
  justify-content: center;
}


@media (max-width: 1250px)  {
  .calendly-inline-widget {
    height: 1200px !important;
  }
}

@media (max-width: 1026px) and (min-width: 1020px)  {
  .ipadGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0rem;
  }
}


.country-code-menu > .MuiPaper-root { 
   
  scrollbar-width: none;
  /* Hide scrollbar for IE, Edge, and Chrome */
  -ms-overflow-style: none;
}