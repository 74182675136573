.request-access-form {
  input {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    color: #717188;
    padding: 12px 16px;
    font-size: 14px;

    &:focus-visible {
      border: 1px solid #e7e7e7;
      outline: none;
    }

    &::placeholder {
      color: #717188;
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #717188;
    }
  }

  .MuiInputBase-root {
    width: 100%;
    border-image: none !important;
    border-image-width: 0px !important;
    // border: 1px solid #e7e7e7;
    border: none !important;
    outline: none !important;
    border-radius: 8px;
    color: #717188;
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
      outline: 0 !important;
    }

    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      border: none;
      outline: none;
      box-shadow: none;
    }

    .MuiSelect-select {
      border: 1px solid #e7e7e7 !important;
      border-radius: 8px;
      padding: 12px 16px;
      &:focus-visible,
      &:hover {
        border: 1px solid #e7e7e7 !important;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
